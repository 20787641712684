import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import withPrismicPreview from '$utils/withPrismicPreview';

import { mixins } from '$styles';

import { SEO } from '$components/global/SEO';
import { PageContainer as _PageContainer } from '$components/global/PageContainer';
import SliceZone from '$components/global/SliceZone';

export const pageQuery = graphql`
  query ($uid: String!, $lang: String!) {
		prismicPageStandby(uid: { eq: $uid }, lang: { eq: $lang }) {
			...Base_Standby
			data {
				...SEO_Standby,
				body {
					...StandbyIntro_Standby
					...StandbyEminente_Standby
				}
			}
		}
	}
`;

export default withPrismicPreview(({ className, ...props }) => {
	const data = props.data?.prismicPageStandby?.data || {};
	return (
		<PageContainer className={className} data={data}>
			<SEO {...data} />
			<SliceZone {...data} />
		</PageContainer>
	);
});

const PageContainer = styled(_PageContainer)`
`;

